import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'updateEnergyDataInput',
  props: ['rowData'],
  components: { DatePicker },
  watch: {
    currentPage: function() {
      this.getEnergyDataInputById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEnergyDataInputById();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      defaultValue: {
        text: null,
        value: null
      },
      batchName: null,
      batchHdrId: null,
      project: {
        text: null,
        value: null
      },
      billingPeriodFrom: null,
      billingPeriodTo: null,
      batchStatus: null,
      batchRemark: null,
      statusMsg: null,
      glDate: null,
      energyInputDtlsData: [
        // {
        //   trx_type: null,
        //   trx_type_id: null,
        //   unit_type: null,
        //   unit_type_id: null,
        //   unit_name: null,
        //   unit_id: null,
        //   sub_unit_name: null,
        //   sub_unit_id: null,
        //   customer_name: null,
        //   customer_id: null,
        //   customer_site_name: null,
        //   customer_site_id: null,
        //   bill_date: null,
        //   source: null,
        //   source_id: null,
        //   payment_term: null,
        //   payment_term_id: null,
        //   currency_name: null,
        //   currency_id: null,
        //   billing_period_from: null,
        //   billing_period_to: null,
        //   gl_date: null,
        //   bill_category: null,
        //   bill_category_id: null,
        //   comp_group_name: null,
        //   comp_group_id: null,
        //   comp_name: null,
        //   comp_id: null,
        //   qty: null,
        //   rate: null,
        //   uom: null,
        //   tax_cat_name: null,
        //   tax_cat_id: null,
        //   sac_code: null,
        //   hsn_code: null,
        //   revenue_acc: null,
        //   due_date: null,
        //   meter_no: null,
        //   opening_reading: null,
        //   closing_reading: null,
        //   net_consumption: null,
        //   remak: null,
        //   line_status: null,
        //   line_status_msg: null,
        //   doc_num: null
        // }
      ],
      energyInputDtlsFields: [
        // {
        //   key: 'add',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        // {
        //   key: 'remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'trx_type',
          label: 'TRX Type',
          class: 'text-center'
        },
        {
          key: 'unit_type',
          label: 'Unit Type',
          class: 'text-center'
        },
        {
          key: 'unit_name',
          label: 'Unit Name',
          class: 'text-center'
        },
        {
          key: 'sub_unit_name',
          label: 'Sub Unit Name',
          class: 'text-center'
        },

        {
          key: 'customer_name',
          label: 'Customer Name',
          class: 'text-center'
        },
        {
          key: 'customer_id',
          label: 'Customer No.',
          class: 'text-center'
        },
        {
          key: 'customer_site_name',
          label: 'Customer Site Name',
          class: 'text-center'
        },
        {
          key: 'customer_site_id',
          label: 'Customer Site No.',
          class: 'text-center'
        },
        {
          key: 'alt_customer_name',
          label: 'Alternative Customer Name',
          class: 'text-center'
        },
        {
          key: 'alt_customer_id',
          label: 'Alternative Customer Id',
          class: 'text-center'
        },
        {
          key: 'alt_customer_site_name',
          label: 'Alternative Customer Site Name',
          class: 'text-center'
        },
        {
          key: 'alt_customer_site_id',
          label: 'Alternative Customer Site Id',
          class: 'text-center'
        },
        {
          key: 'bill_date',
          label: 'Billing Date',
          class: 'text-center'
        },
        {
          key: 'source',
          label: 'Source',
          class: 'text-center'
        },
        {
          key: 'payment_term',
          label: 'Payment Term',
          class: 'text-center'
        },
        {
          key: 'currency_name',
          label: 'Currency',
          class: 'text-center'
        },
        {
          key: 'billing_period_from',
          label: 'Billing Period From',
          class: 'text-center'
        },
        {
          key: 'billing_period_to',
          label: 'Billing Period To',
          class: 'text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'bill_category',
          label: 'Bill Category',
          class: 'text-center'
        },
        {
          key: 'comp_group_name',
          label: 'Component Group',
          class: 'text-center'
        },
        {
          key: 'comp_name',
          label: 'Component Name',
          class: 'text-center'
        },
        {
          key: 'qty',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'rate',
          label: 'Rate',
          class: 'text-center'
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-center'
        },
        {
          key: 'tax_cat_name',
          label: 'Tax Category',
          class: 'text-center'
        },
        {
          key: 'sac_code',
          label: 'SAC Code',
          class: 'text-center'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code',
          class: 'text-center'
        },
        {
          key: 'revenue_acc',
          label: 'Revenue Account',
          class: 'text-center'
        },
        {
          key: 'due_date',
          label: 'Due Date',
          class: 'text-center'
        },
        {
          key: 'meter_no',
          label: 'Meter No.',
          class: 'text-center'
        },
        {
          key: 'opening_reading',
          label: 'Opening Reading',
          class: 'text-center'
        },
        {
          key: 'closing_reading',
          label: 'Closing Reading',
          class: 'text-center'
        },

        {
          key: 'net_consumption',
          label: 'Net Consumption',
          class: 'text-center'
        },
        {
          key: 'remak',
          label: 'Remarks',
          class: 'text-center'
        },
        {
          key: 'line_status',
          label: 'Line Status',
          class: 'text-center'
        },
        {
          key: 'line_status_msg',
          label: 'Line Status Message',
          class: 'text-center'
        },
        {
          key: 'doc_num',
          label: 'Document Number',
          class: 'text-center'
        },
        {
          key: 'pre_reading_date',
          label: 'Previous Reading Date',
          class: 'text-center'
        },
        {
          key: 'curr_reading_date',
          label: 'Current Reading Date',
          class: 'text-center'
        },
        {
          key:'billing_plan',
          label:'Billing Plan',
          class:'text-center'
        }
       
      ],
      showValueSetModal: false,
      unsubscribe: null,
      editMode: false,
      specIndex: null,
      payload: null,
      showExcelUploadModal: false,
      templateId: null,
      requestId: null,
      requestNumId: 0,
      totalRecord: null,
      totalBill: null,
      billGenerated: null,
      billInError: null,
      entityType: null,
      vSetType: null,
      unitName: {
        text: null,
        value: null
      },
      customerName: {
        text: null,
        value: null
      },
      customerSiteName: {
        text: null,
        value: null
      },
      // meterNum: {
      //   text: null,
      //   value: null
      // },
      lineStatus: {
        text: null,
        value: null
      },
      netConsum: null,
      initialEnergyInputData: [],
      isSeparateBill: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      requestStatus: null,
      variantType: null,
      requestStatus1: null,
      unitType: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showEnergyDataFormModal = true;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
          this.getEnergyDataInputById();
        }
        if (actionName === 'save') {
          if(this.editMode === true){
            this.updateEnergyInputData();
          } else {
            this.$bvToast.toast('Please enable editMode', {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
             }
         }    
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'energyDataInput/excelDownloadEnergyInputData',
            'Energy_Data_Input',
            () => (this.loader = false)
          );
        }
      }
    });
    if (!this.rowData) {
      this.editMode = true;
    } else {
      this.getEnergyDataInputById();
      this.getEnergyDataInputHdrDtlsById();
    }
  },
  methods: {
    getEnergyDataInputById() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        energy_batch_hdr_id: this.rowData.energy_batch_hdr_id,
        unit_id: this.unitName.value,
        customer_id: this.customerName.value,
        customer_site_id: this.customerSiteName.value,
        net_consumption: this.netConsum,
        // meter_no: this.meterNum,
        line_status_code: this.lineStatus.value
      };
      this.loader = true;
      this.$store
        .dispatch('energyDataInput/getEnergyDataInputById', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.energyInputDtlsData = resp.data.data.page;
            this.entityType = this.energyInputDtlsData.length
              ? this.energyInputDtlsData[0].unit_type
              : null;
            this.initialEnergyInputData = JSON.parse(
              JSON.stringify(resp.data.data.page)
            );
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEnergyDataInputHdrDtlsById() {
      const payload = {
        energyBatchHdrId: this.rowData.energy_batch_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('energyDataInput/getEnergyDataInputHdrDtlsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.autoFilledHdrDtls(result);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateEnergyInputData() {
      const itemDetails = this.energyInputDtlsData.map(elem => {
        return {
          energy_batch_line_id: elem.energy_batch_line_id,
          energy_batch_hdr_id: elem.energy_batch_hdr_id,
          customer_name: elem.customer_name,
          customer_id: elem.customer_id,
          customer_site_id: elem.customer_site_id,
          customer_site_name: elem.customer_site_name,
          bill_date: elem.bill_date,
          gl_date: elem.gl_date,
          bill_category: elem.bill_category_id,
          bill_category_id: elem.bill_category,
          meter_id: elem.meter_id,
          opening_reading: elem.opening_reading,
          closing_reading: elem.closing_reading,
          net_consumption: elem.net_consumption,
          remarks: elem.remak,
          rate: elem.rate,
          tax_cat_id: elem.tax_cat_id,
          qty: elem.qty,
          amount: elem.amount,
          pre_reading_date: elem.pre_reading_date,
          curr_reading_date: elem.curr_reading_date,
          payment_term_id: elem.payment_term_id,
          alt_customer_name: elem.alt_customer_name,
          alt_customer_site_name: elem.alt_customer_site_name,
          alt_customer_id: elem.alt_customer_id,
          alt_customer_site_id: elem.alt_customer_site_id,
          tax_cat_name: elem.tax_cat_name,
          meter_no: elem.meter_no,
          payment_term: elem.payment_term,
          bill_hdr_id: elem.bill_hdr_id
        };
      });
      const payload = {
        energy_batch_hdr_id: this.batchHdrId,
        is_separate_bill: this.isSeparateBill,
        energy_dtls: itemDetails ? itemDetails : null
      };

      this.loader = true;
      this.$store
        .dispatch('energyDataInput/updateEnergyInputData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.getEnergyDataInputById();
            this.getEnergyDataInputHdrDtlsById();
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validGenBatchData(type) {
      this.variantType = type;
      const payload = {
        template_name:
          type === 'validate'
            ? 'FMS_VALIDATE_ENERGY_INPUT_TMP'
            : 'FMS_GENERATE_ENERGY_BILL_TMP'
      };
      this.$store
        .dispatch('template/getTemplateList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.templateId = response.data.data.page[0].template_id;
            this.requestId = response.data.data.page[0].request_id;
            if (this.templateId && this.requestId) {
              this.saveSubmitRequest();
            } else {
              this.$bvToast.toast('Something Went wrong. Please try again', {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap',
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'BLANK',
            no_of_args: 3,
            request_parameter: JSON.stringify(this.batchHdrId),
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: JSON.stringify(this.batchHdrId),
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest(this.variantType);
          }
          // this.$bvToast.toast(resp.data.message, {
          //   title: 'Alert',
          //   variant: 'success',
          //   solid: true
          // });
          // } else {
          //   this.$bvToast.toast(resp.response.data.message, {
          //     title: 'Alert',
          //     variant: 'danger',
          //     solid: true
          //   });
          // }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest(type) {
      if (type === 'validate') {
        this.loader = true;
        this.$store
          .dispatch('template/getSubmitRequest', this.requestNumId)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.requestStatus = resp.data.data[0].status;
              this.requestNumId = resp.data.data[0].id;
              if (this.requestStatus === 'COMPLETED')
              this.getEnergyDataInputById();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else if (type === 'generate') {
        this.loader = true;
        this.$store
          .dispatch('template/getSubmitRequest', this.requestNumId)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.requestStatus1 = resp.data.data[0].status;
              this.requestNumId = resp.data.data[0].id;
              if (this.requestStatus1 === 'COMPLETED')
              this.getEnergyDataInputById();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    autoFilledHdrDtls(item) {
      this.batchName = item.energy_batch_name;
      this.batchHdrId = item.energy_batch_hdr_id;
      this.project = {
        text: item.fms_prj_name,
        value: item.prj_id
      };
      this.billingPeriodFrom = item.billing_period_from;
      this.billingPeriodTo = item.billing_period_to;
      this.batchStatus = item.batch_status;
      this.batchRemark = item.batch_remarks;
      this.statusMsg = item.status_msg;
      this.isSeparateBill = item.is_separate_bill;
      this.unitType = item.unit_type
      },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.energyInputDtlsData[this.specIndex].billing_period_from
      );
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    checkUnitOrSubUnit(entityType, index) {
      this.specIndex = index;
      if (this.project.text && this.energyInputDtlsData[index].unit_type) {
        if (entityType === 'FMS_SUB_UNIT') {
          this.openValueSetModal(
            'FMS_SUB_UNIT_DISPLAY_VSET',
            index,
            null,
            'grid'
          );
        } else if (entityType === 'LEASE_UNIT') {
          this.openValueSetModal('LEASE_UNIT_VSET', index, null, 'grid');
        }
      } else if (
        this.project.text &&
        !this.energyInputDtlsData[index].unit_type
      ) {
        this.$bvToast.toast('Please Select Entity Type first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (
        !this.project.text &&
        this.energyInputDtlsData[index].unit_type
      ) {
        this.$bvToast.toast('Please Select Project Name first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        this.$bvToast.toast(
          'Please Select Project Name and Entity Type first',
          {
            title: 'Alert',
            variant: 'danger',
            solid: true
          }
        );
      }
    },
    checkUnitTypeForFilter(entityType) {
      if (entityType === 'FMS_SUB_UNIT') {
        this.openValueSetModal(
          'FMS_SUB_UNIT_DISPLAY_VSET',
          null,
          null,
          'filter'
        );
      } else if (entityType === 'LEASE_UNIT') {
        this.openValueSetModal('LEASE_UNIT_VSET', null, null, 'filter');
      }
    },
    meterValueSetValidate(vsetCode, item, index) {
      this.specIndex = index;
      let entType = this.energyInputDtlsData[this.specIndex].unit_type;
      if (entType) {
        if (entType === 'FMS_SUB_UNIT') {
          if (this.energyInputDtlsData[this.specIndex].sub_unit_name) {
            const meterPayload = {
              fms_prj_id: item.prj_id,
              entity_type: item.unit_type,
              entity_id: item.sub_unit_id
            };
            this.openValueSetModal(
              vsetCode,
              this.specIndex,
              meterPayload,
              'grid'
            );
          } else {
            this.$bvToast.toast('Please Select Sub Unit Name first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        } else if (entType === 'LEASE_UNIT') {
          const meterPayload = {
            fms_prj_id: item.prj_id,
            entity_type: item.unit_type,
            entity_id: item.unit_id
          };
          this.openValueSetModal(
            vsetCode,
            this.specIndex,
            meterPayload,
            'grid'
          );
        }
      } else {
        this.$bvToast.toast('Please Select Unit Type first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    openValueSetModal(vsetCode, index, meterPayload, type) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.parent_value_set_id = null;
      this.vSetType = type;
      if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_SITE_VSET) {
        if (type === 'grid') {
          if (this.energyInputDtlsData[index].customer_name === null) {
            this.showValueSetModal = false;
            this.$bvToast.toast('Please select customer name first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else {
            this.parent_value_set_id = this.energyInputDtlsData[
              index
            ].customer_id;
            this.showValueSetModal = true;
          }
        } else if (type === 'filter') {
          if (this.customerName.text) {
            this.parent_value_set_id = this.customerName.value;
            this.showValueSetModal = true;
          } else {
            this.showValueSetModal = false;
            this.$bvToast.toast('Please select customer name first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        } else if (type === 'customer') {
          if (this.energyInputDtlsData[index].alt_customer_name === null) {
            this.showValueSetModal = false;
            this.$bvToast.toast(
              'Please select alternative customer name first',
              {
                title: 'Alert',
                variant: 'danger',
                solid: true
              }
            );
          } else {
            this.parent_value_set_id = this.energyInputDtlsData[
              index
            ].alt_customer_id;
            this.showValueSetModal = true;
          }
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        if (this.energyInputDtlsData[index].comp_group_name === null) {
          this.showValueSetModal = false;
          this.$bvToast.toast('Please select component group first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        } else {
          this.parent_value_set_id = this.energyInputDtlsData[
            index
          ].comp_group_id;
          this.showValueSetModal = true;
        }
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = 1;
        this.showValueSetModal = true;
      }else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET
      ) {
        this.parent_value_set_id = this.project.value;
        this.showValueSetModal = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.METER_NO_VAL) {
       this.parent_value_set_id = meterPayload;
        this.showValueSetModal = true; 
      } else {
        this.showValueSetModal = true;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE_VSET) {
        this.energyInputDtlsData[this.specIndex].trx_type = item.value_code;
        this.energyInputDtlsData[this.specIndex].trx_type_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_LIST) {
        this.energyInputDtlsData[this.specIndex].source = item.value_code;
        this.energyInputDtlsData[this.specIndex].source_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PAYMENT_TERM) {
        this.energyInputDtlsData[this.specIndex].payment_term = item.value_code;
        this.energyInputDtlsData[this.specIndex].payment_term_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CAT_VSET) {
        this.energyInputDtlsData[this.specIndex].bill_category =
          item.value_meaning;
        this.energyInputDtlsData[this.specIndex].bill_category_id =
          item.value_code;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CURRENCY_LIST) {
        this.energyInputDtlsData[this.specIndex].currency_name =
          item.value_code;
        this.energyInputDtlsData[this.specIndex].currency_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_VSET) {
        if (this.vSetType === 'grid') {
          this.energyInputDtlsData[this.specIndex].customer_name =
            item.value_code;
          this.energyInputDtlsData[this.specIndex].customer_id =
            item.value_set_dtl_id;
          this.energyInputDtlsData[this.specIndex].customer_site_name = null;
          this.energyInputDtlsData[this.specIndex].customer_site_id = null;
          this.energyInputDtlsData = JSON.parse(
            JSON.stringify(this.energyInputDtlsData)
          );
        } else if (this.vSetType === 'filter') {
          this.customerName = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        } else if (this.vSetType === 'customer') {
          this.energyInputDtlsData[this.specIndex].alt_customer_name =
            item.customer_name;
          this.energyInputDtlsData[this.specIndex].alt_customer_id =
            item.customer_id;
          this.energyInputDtlsData[
            this.specIndex
          ].alt_customer_site_name = null;
          this.energyInputDtlsData[this.specIndex].alt_customer_site_id = null;
          this.energyInputDtlsData = JSON.parse(
            JSON.stringify(this.energyInputDtlsData)
          );
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_SITE_VSET) {
        if (this.vSetType === 'grid') {
          this.energyInputDtlsData[this.specIndex].customer_site_name =
            item.value_code;
          this.energyInputDtlsData[this.specIndex].customer_site_id =
            item.value_set_dtl_id;
          this.energyInputDtlsData = JSON.parse(
            JSON.stringify(this.energyInputDtlsData)
          );
        } else if (this.vSetType === 'customer') {
          this.energyInputDtlsData[this.specIndex].alt_customer_site_name =
            item.value_code;
          this.energyInputDtlsData[this.specIndex].alt_customer_site_id =
            item.value_set_dtl_id;
          this.energyInputDtlsData = JSON.parse(
            JSON.stringify(this.energyInputDtlsData)
          );
        } else if (this.vSetType === 'filter') {
          this.customerSiteName = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.COMPONENT_GRP) {
        this.energyInputDtlsData[this.specIndex].comp_group_name =
          item.value_code;
        this.energyInputDtlsData[this.specIndex].comp_group_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData[this.specIndex].comp_name = null;
        this.energyInputDtlsData[this.specIndex].comp_id = null;
        this.energyInputDtlsData[this.specIndex].uom = null;
        this.energyInputDtlsData[this.specIndex].uom_id = null;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.energyInputDtlsData[this.specIndex].comp_name = item.value_code;
        this.energyInputDtlsData[this.specIndex].comp_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData[this.specIndex].uom = item.uom_class_meaning;
        this.energyInputDtlsData[this.specIndex].uom_id = item.uom_class;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ENTITY_TYPE_VSET) {
        this.energyInputDtlsData[this.specIndex].unit_type = item.value_code;
        this.energyInputDtlsData[this.specIndex].unit_type_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData[this.specIndex].unit_name = null;
        this.energyInputDtlsData[this.specIndex].unit_id = null;
        this.energyInputDtlsData[this.specIndex].sub_unit_name = null;
        this.energyInputDtlsData[this.specIndex].sub_unit_id = null;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET
      ) {
        if (this.vSetType === 'grid') {
          this.energyInputDtlsData[this.specIndex].unit_name = item.value_code;
          this.energyInputDtlsData[this.specIndex].unit_id =
            item.value_set_dtl_id;
          this.energyInputDtlsData[this.specIndex].sub_unit_name =
            item.fms_sub_unit_name;
          this.energyInputDtlsData[this.specIndex].sub_unit_id =
            item.fms_sub_unit_id;
          this.energyInputDtlsData = JSON.parse(
            JSON.stringify(this.energyInputDtlsData)
          );
        } else if (this.vSetType === 'filter') {
          this.unitName = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET) {
        if (this.vSetType === 'grid'){
          this.energyInputDtlsData[this.specIndex].unit_name = item.value_code;
          this.energyInputDtlsData[this.specIndex].unit_id =
            item.value_set_dtl_id;
          this.energyInputDtlsData = JSON.parse(
            JSON.stringify(this.energyInputDtlsData)
          );
        } else if (this.vSetType === 'filter'){
          this.unitName = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }
       } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.energyInputDtlsData[this.specIndex].tax_cat_name = item.value_code;
        this.energyInputDtlsData[this.specIndex].tax_cat_id =
          item.value_set_dtl_id;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts) {
        this.energyInputDtlsData[this.specIndex].revenue_acc = item.value_code;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.METER_NO_VAL) {
        this.energyInputDtlsData[this.specIndex].meter_no = item.meter_no;
        this.energyInputDtlsData[this.specIndex].meter_id = item.meter_id;
        this.energyInputDtlsData = JSON.parse(
          JSON.stringify(this.energyInputDtlsData)
        );
      } else if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
        this.lineStatus.text = item.value_meaning;
        this.lineStatus.value = item.value_code;
      } 
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'CUSTOMERVSET') {
        this.energyInputDtlsData[index].customer_name = null;
        this.energyInputDtlsData[index].customer_id = null;
        this.energyInputDtlsData[index].customer_site_name = null;
        this.energyInputDtlsData[index].customer_site_id = null;
      } else if (vsetCode === 'CUSTOMERSITEVSET') {
        this.energyInputDtlsData[index].customer_site_name = null;
        this.energyInputDtlsData[index].customer_site_id = null;
      } else if (vsetCode === 'ENTITYTYPEVSET') {
        this.energyInputDtlsData[index].unit_type = null;
        this.energyInputDtlsData[index].unit_type_id = null;
        this.energyInputDtlsData[index].unit_name = null;
        this.energyInputDtlsData[index].unit_id = null;
        this.energyInputDtlsData[index].sub_unit_name = null;
        this.energyInputDtlsData[index].sub_unit_id = null;
      } else if (vsetCode === 'UNIT') {
        this.energyInputDtlsData[index].unit_name = null;
        this.energyInputDtlsData[index].unit_id = null;
        this.energyInputDtlsData[index].sub_unit_name = null;
        this.energyInputDtlsData[index].sub_unit_id = null;
      } else if (vsetCode === 'COMPONENTGRP') {
        this.energyInputDtlsData[index].comp_group_name = null;
        this.energyInputDtlsData[index].comp_group_id = null;
        this.energyInputDtlsData[index].comp_name = null;
        this.energyInputDtlsData[index].comp_id = null;
        this.energyInputDtlsData[index].uom = null;
        this.energyInputDtlsData[index].uom_id = null;
      } else if (vsetCode === 'FMSCOMPONENT') {
        this.energyInputDtlsData[index].comp_name = null;
        this.energyInputDtlsData[index].comp_id = null;
        this.energyInputDtlsData[index].uom = null;
        this.energyInputDtlsData[index].uom_id = null;
      } else if (vsetCode === 'TAXCATEGORYNAMEVSET') {
        this.energyInputDtlsData[index].tax_cat_name = null;
        this.energyInputDtlsData[index].tax_cat_id = null;
      } else if (vsetCode === 'TRXTYPEVSET') {
        this.energyInputDtlsData[index].trx_type = null;
        this.energyInputDtlsData[index].trx_type_id = null;
      } else if (vsetCode === 'SOURCELIST') {
        this.energyInputDtlsData[index].source = null;
        this.energyInputDtlsData[index].source_id = null;
      } else if (vsetCode === 'FMSPAYMENTTERM') {
        this.energyInputDtlsData[index].payment_term = null;
        this.energyInputDtlsData[index].payment_term_id = null;
      } else if (vsetCode === 'CURRENCYLIST') {
        this.energyInputDtlsData[index].currency_name = null;
        this.energyInputDtlsData[index].currency_id = null;
      } else if (vsetCode === 'BILLCATVSET') {
        this.energyInputDtlsData[index].bill_category = null;
        this.energyInputDtlsData[index].bill_category_id = null;
      } else if (vsetCode === 'revenueAcc') {
        this.energyInputDtlsData[index].revenue_acc = null;
      } else if (vsetCode === 'METERNOVAL') {
        this.energyInputDtlsData[index].meter_no = null;
        this.energyInputDtlsData[index].meter_id = null;
      } else if (vsetCode === this.unitName.text) {
        this.unitName = this.defaultValue;
      } else if (vsetCode === this.customerName.text) {
        this.customerName = this.defaultValue;
      } else if (vsetCode === this.customerSiteName.text) {
        this.customerSiteName = this.defaultValue;
      } else if (vsetCode === 'CUSTOMERVSET1') {
        this.energyInputDtlsData[index].alt_customer_name = null;
        this.energyInputDtlsData[index].alt_customer_id = null;
        this.energyInputDtlsData[index].alt_customer_site_name = null;
        this.energyInputDtlsData[index].alt_customer_site_id = null;
      } else if (vsetCode === 'CUSTOMERSITEVSET1') {
        this.energyInputDtlsData[index].alt_customer_site_name = null;
        this.energyInputDtlsData[index].alt_customer_site_id = null;
      } else if (vsetCode === 'STATUS_FLAG') {
        this.lineStatus.text = null;
        this.lineStatus.value = null;
      }
    },
    // addNewRow() {
    //   this.energyInputDtlsData.push({
    //     energy_batch_hdr_id: 0,
    //     trx_type: null,
    //     trx_type_id: null,
    //     unit_type: null,
    //     unit_type_id: null,
    //     unit_name: null,
    //     unit_id: null,
    //     sub_unit_name: null,
    //     sub_unit_id: null,
    //     customer_name: null,
    //     customer_id: null,
    //     customer_site_name: null,
    //     customer_site_id: null,
    //     bill_date: null,
    //     source: null,
    //     source_id: null,
    //     payment_term: null,
    //     payment_term_id: null,
    //     currency_name: null,
    //     currency_id: null,
    //     billing_period_from: null,
    //     billing_period_to: null,
    //     gl_date: null,
    //     bill_category: null,
    //     bill_category_id: null,
    //     comp_group_name: null,
    //     comp_group_id: null,
    //     comp_name: null,
    //     comp_id: null,
    //     qty: null,
    //     rate: null,
    //     uom: null,
    //     tax_cat_name: null,
    //     tax_cat_id: null,
    //     sac_code: null,
    //     hsn_code: null,
    //     revenue_acc: null,
    //     due_date: null,
    //     meter_no: null,
    //     opening_reading: null,
    //     closing_reading: null,
    //     net_consumption: null,
    //     remak: null,
    //     line_status: null,
    //     line_status_msg: null,
    //     doc_num: null
    //   });
    // },
    // removeRow(index) {
    //   this.energyInputDtlsData.splice(index, 1);
    // }
    clear() {
      this.unitName = this.defaultValue;
      this.customerName = this.defaultValue;
      this.customerSiteName = this.defaultValue;
      this.meterNum = this.defaultValue;
      this.netConsum = null;
      this.lineStatus = this.defaultValue;
      this.energyInputDtlsData = [];
      this.requestStatus = null;
      this.requestStatus1 = null;
      this.getEnergyDataInputById();
      
    },
    calculateAmount(item) {
      item.amount = item.qty * item.rate;
    },
    calculateNetConsumption(item) {
      item.net_consumption = item.closing_reading - item.opening_reading;
    },
    // validatePeriod(glDate) {
    //   this.module && this.legalEntity.value && this.operatingUnit.value
    //     ? this.getValidatePeriodDate(glDate)
    //     : alert('Please Select Module, Legal Entity and Operating Unit');
    //   this.glDate = null;
    // },
    getValidatePeriodDate(glDate) {
      const payload = {
        module_id: 22,
        le_id: 2,
        entity_id: 11,
        gl_date: glDate
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.respMessage = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              this.glDate = commonHelper.formattedDate(new Date());
            } else {
              this.glDate = glDate;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
