import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import updateEnergyDataInput from './updateEnergyDataInput';
import addEnergyDataInput from './addEnergyDataInput';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'energyDataInput',
  components: { DatePicker, updateEnergyDataInput, addEnergyDataInput },
  watch: {
    currentPage: function() {
      this.getEnergyDataInput();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEnergyDataInput();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      project: {
        text: null,
        value: null
      },
      createdBy: {
        text: null,
        value: null
      },
      defaultValue: {
        text: null,
        value: null
      },
      batchStatus: {
        text: null,
        value: null
      },
      batchName: null,
      billingPeriodFrom: null,
      billingPeriodTo: null,
      fileName: null,
      remark: null,
      energyDataInputData: [],
      energyDataInputFields: [
        {
          key: 'energy_batch_name',
          label: 'Batch Name',
          class: 'text-center'
        },
        {
          key: 'fms_prj_name',
          label: 'Project Name',
          class: 'text-center'
        },
        {
          key: 'billing_period_from',
          label: 'Billing Period From',
          class: 'text-center'
        },
        {
          key: 'billing_period_to',
          label: 'Billing Period To',
          class: 'text-center'
        },
        {
          key: 'batch_status',
          label: 'Batch Status',
          class: 'text-center'
        },
        {
          key: 'batch_remarks',
          label: 'Batch Remark',
          class: 'text-center'
        },
        {
          key: 'status_msg',
          label: 'Status Message',
          class: 'text-center'
        },
        {
          key: 'file_name',
          label: 'File Name',
          class: 'text-center'
        },
        {
          key: 'unit_type',
          label: 'Unit Type',
          class: 'text-center'
        }
        // {
        //   key: 'unit_type',
        //   label: 'Unit Type',
        //   class: 'text-center'
        // },
        // {
        //   key: 'unit_name',
        //   label: 'Unit Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'sub_unit_name',
        //   label: 'Sub Unit Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'customer_name',
        //   label: 'customer Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'customer_site_name',
        //   label: 'Customer Site Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'currency_name',
        //   label: 'Currency',
        //   class: 'text-center'
        // },
        // {
        //   key: 'bill_date',
        //   label: 'Bill Date',
        //   class: 'text-center'
        // },
        // {
        //   key: 'gl_date',
        //   label: 'GL Date',
        //   class: 'text-center'
        // },
        // {
        //   key: 'bill_category',
        //   label: 'Bill Category',
        //   class: 'text-center'
        // },
        // {
        //   key: 'comp_group_name',
        //   label: 'Component Group Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'comp_name',
        //   label: 'Component Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'meter_no',
        //   label: 'Meter No.',
        //   class: 'text-center'
        // },
        // {
        //   key: 'opening_reading',
        //   label: 'Opening Reading',
        //   class: 'text-center'
        // },
        // {
        //   key: 'net_consumption',
        //   label: 'Net Consumption',
        //   class: 'text-center'
        // },
        // // {
        // //   key: 'remarks',
        // //   label: 'Remarks',
        // //   class: 'text-center'
        // // },
        // {
        //   key: 'rate',
        //   label: 'Rate',
        //   class: 'text-center'
        // },
        // {
        //   key: 'tax_cat_name',
        //   label: 'Tax Category',
        //   class: 'text-center'
        // },
        // {
        //   key: 'sac_code',
        //   label: 'SAC Code',
        //   class: 'text-center'
        // },
        // {
        //   key: 'revenue_ccid',
        //   label: 'Revenue CCID',
        //   class: 'text-center'
        // },
        // {
        //   key: 'revenue_acc',
        //   label: 'Revenue Account',
        //   class: 'text-center'
        // },
        // {
        //   key: 'line_status',
        //   label: 'Line Status',
        //   class: 'text-center'
        // },
        // {
        //   key: 'line_status_msg',
        //   label: 'Line Status Message',
        //   class: 'text-center'
        // },
        // {
        //   key: 'doc_num',
        //   label: 'Document No.',
        //   class: 'text-center'
        // },
        // {
        //   key: 'exchange_rate',
        //   label: 'Exchange Rate',
        //   class: 'text-center'
        // },
        // {
        //   key: 'due_date',
        //   label: 'Due Date',
        //   class: 'text-center'
        // }
      ],
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: true,
        saveAllow: true,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      rowData: null,
      showValueSetModal: false,
      unsubscribe: null,
      showEnergyDataFormModal: false,
      showAddEnerguDataInputModal: false,
      unitType: {
        text: null,
        value: null
      },
    };
  },
  validations: {
    project: { text: { required } }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddEnerguDataInputModal = true;
        }
      }
    });
  },
  methods: {
    getEnergyDataInput() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          fms_prj_id: this.project.value,
          batch_name: this.batchName,
          // createdBy: this.createdBy.value,
          user_id: this.createdBy.value,
          // user_id: this.$store.state.auth.userId,
          billing_period_from: this.billingPeriodFrom,
          billing_period_to: this.billingPeriodTo,
          batch_status: this.batchStatus.value,
          remark: this.remark,
          file_name: this.fileName,
          unit_type :this.unitType.value
        };
        this.loader = true;
        this.$store
          .dispatch('energyDataInput/getEnergyDataInput', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.energyDataInputData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.billingPeriodFrom);
    },
    rowSelected(item) {
      this.rowData = item;
      this.showHideEnergyDataFormModal(true);
    },
    showHideEnergyDataFormModal(flag) {
      this.showEnergyDataFormModal = flag;
    },
    showHideAddEnerguDataInputModal(flag) {
      this.showAddEnerguDataInputModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GET_USER_NAME_BY_USER_ID
      ) {
        this.createdBy = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
        this.batchStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ENTITY_TYPE_VSET) {
        this.unitType = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'FMS_PRJ_INTERNAL_BY_USER_ID') {
        this.project = this.defaultValue;
      } else if (vsetCode === 'batchName') {
        this.batchName = null;
      } else if (vsetCode === 'createdBy') {
        this.createdBy = this.defaultValue;
      } else if (vsetCode === 'batchStatus') {
        this.batchStatus = this.defaultValue;
      } else if (vsetCode === 'fileName') {
        this.fileName = null;
      } else if (vsetCode === 'remark') {
        this.remark = null;
      } else if (vsetCode === 'ENTITY_TYPE_VSET') {
        this.unitType = {
          text: null,
          value: null
        };
      }
    },
    clear() {
      this.project = this.defaultValue;
      this.createdBy = this.defaultValue;
      this.batchStatus = this.defaultValue;
      this.batchName = null;
      this.billingPeriodFrom = null;
      this.billingPeriodTo = null;
      this.fileName = null;
      this.remark = null;
      this.energyDataInputData = [];
      this.unitType.text =null;
      this.unitType.value = null
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
